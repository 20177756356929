import { AppBar, Box, Breadcrumbs, Button, Container, Link, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { Component } from "react";
import Podcast from "./models/podcast";
import Trigger from "./models/trigger";
import SuggestedChaptersTriggersTable from "./routes/SuggestedChaptersTriggersTable";
import { withRouter } from "./routes/withRouter";
import { makeAuthenticatedRequest } from './utils/api';
const _ = require('lodash');


interface IProps {
    params_url: string | null,
    podcastId: any,
    location: any,
}

interface IState {
    podcastId: string,
    studioPodcastDetail: Podcast | null,
    triggers: Array<Trigger>
}

class SuggestedChaptersTriggersController extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            podcastId: this.props.podcastId,
            studioPodcastDetail: null,
            triggers: []
        }
        this.getStudioPodcast();
    }

    handleUpdateTriggers = async () => {
        try {
            await makeAuthenticatedRequest('/api/podcast/triggers/update', {
                method: 'POST',
                body: {
                    'triggers': this.state.triggers,
                    'podcastId': this.state.podcastId
                }
            });
        } catch (error) {
            console.error('Error updating triggers:', error);
        }
    }

    getStudioPodcast = async () => {
        try {
            const response = await makeAuthenticatedRequest('/api/studio/podcast', {
                method: 'POST',
                body: {
                    'podcastId': this.props.podcastId
                }
            });
            
            if (response != null) {
                let podcastDetail = Podcast.initFromJson(response);
                this.setState({
                    studioPodcastDetail: podcastDetail
                });
            }
        } catch (error) {
            console.error('Error fetching studio podcast:', error);
        }
    }

    getLatestTriggers = (triggerResult: any) => {
        console.log('get latest triggers');
        console.log(triggerResult);
        if (!_.isEqual(triggerResult, this.state.triggers)) {
            this.setState({ triggers: triggerResult });
        }
    }

    render() {
        return <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <AppBar position="absolute">
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        HyperCatcher Studio
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>

                <Breadcrumbs >
                    <Link href="/dashboard"><Typography> Home </Typography></Link>
                    <Link href={`/dashboard/${this.props.podcastId}`}><Typography>{`${this.props.podcastId}`}</Typography></Link>
                    <Typography>Smart Chapters</Typography>
                </Breadcrumbs>

                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <p className="title">Publish Options</p>
                        <Stack spacing={2} direction="row">
                            <Button variant="outlined" onClick={() => { this.handleUpdateTriggers() }}>Save Changes</Button>
                        </Stack>
                    </Paper>
                </Container >

                <section className="topic">
                    <h2>Chapter Triggers</h2>
                </section>

                <div className='nes-table-responsive'>
                    {this.state.podcastId
                        ? <SuggestedChaptersTriggersTable
                            studioPodcastDetail={this.state.studioPodcastDetail}
                            getLatestTriggers={this.getLatestTriggers}
                        /> : null}
                </div>
            </Container>
        </Box >
    }
}

export default withRouter(SuggestedChaptersTriggersController);
