import React, { useState } from "react";

import { ITableProps, kaReducer, Table } from "ka-table";
import { loadData, updateData, deleteRow, hideNewRow, saveNewRow, insertRow, updateCellValue, closeEditor } from "ka-table/actionCreators";
import { ICellEditorProps, ICellTextProps, IHeadCellProps } from 'ka-table/props';
import {
    DataType,
    SortDirection,
    SortingMode,
    EditingMode
} from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Stack, TableContainer, Paper } from '@mui/material';
import { fixupChapters, uuidv4, getTimeStringFromSecs } from "../chapterHelper";
import { kaPropsUtils } from 'ka-table/utils';
import SmartChapter from "../models/smartChapter";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const _ = require('lodash');

interface ITimeAwareHeadCellProps extends IHeadCellProps {
    totalTimePlayed: number,
}

interface IHCCellEditorProps extends ICellEditorProps {
    smartChapters: Array<SmartChapter>

}

const DeleteRow: React.FC<ICellTextProps> = ({ dispatch, rowKeyValue }) => {
    return (
        <Button
            onClick={() => dispatch(deleteRow(rowKeyValue))}
        >x</Button>
    );
};

const AddButton: React.FC<ITimeAwareHeadCellProps> = ({
    dispatch, totalTimePlayed
}) => {
    return (
        <div className='plus-cell-button'>
            <Button variant="outlined"
                onClick={() => {
                    let keyVal = uuidv4();
                    const newRow = {
                        id: keyVal,
                        title: '',
                    };
                    dispatch(insertRow(newRow, { rowKeyValue: keyVal }))
                }}>
                <AddCircleOutlineIcon></AddCircleOutlineIcon>
            </Button>
        </div>
    );
};

const SaveButton: React.FC<ICellEditorProps> = ({
    dispatch
}) => {
    const saveNewData = () => {
        const rowKeyValue = uuidv4();
        dispatch(saveNewRow(rowKeyValue, {
            validate: true
        }));
    };
    return (
        <div className='buttons'>
            <Button
                onClick={saveNewData}
            >s</Button>
            <Button
                onClick={() => dispatch(hideNewRow())}
            >c</Button>
        </div>
    );
};

const tablePropsInit: ITableProps = {
    columns: [
        { dataType: DataType.String, key: "id", title: "id", width: 100, visible: false, sortDirection: SortDirection.Ascend },
        { dataType: DataType.String, key: "name", title: "Name", width: 100, },
        { dataType: DataType.String, key: "title", title: "Title", width: 100, },
        { dataType: DataType.String, key: "image", title: "Image", width: 100 },
        { dataType: DataType.String, key: "url", title: "Url", width: 100 },
        { title: "Delete", key: ':delete', width: 50, style: { textAlign: 'center' }, isEditable: false },
        {
            key: 'addColumn',
            style: { width: 50 }
        },
    ],
    singleAction: loadData(),
    editingMode: EditingMode.Cell,
    sortingMode: SortingMode.Single,
    rowKeyField: "id",
    columnResizing: true
};

const SmartChapterTable: React.FC<any> = (props: any) => {

    const totalTimePlayed: number = Math.ceil(props.totalTimePlayed);
    const [tableProps, changeTableProps] = useState(tablePropsInit);
    const [smartChapters, setSmartChapters] = useState([]);
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevProps: ITableProps) => kaReducer(prevProps, action));
    };

    if (props.studioPodcastDetail && props.studioPodcastDetail.smartChapters && !_.isEqual(props.studioPodcastDetail.smartChapters, smartChapters)) {
        setSmartChapters(props.studioPodcastDetail.smartChapters)
        dispatch(updateData(props.studioPodcastDetail.smartChapters));
    }

    props.getLatestSmartChapters(kaPropsUtils.getData(tableProps));

    return (
        <Container maxWidth="xl">
            <TableContainer component={Paper}>
                <Table
                    {...tableProps}
                    childComponents={{
                        cellEditor: {
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'addColumn': return <SaveButton {...props} />;
                                }
                            }
                        },
                        cellText: {
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'image': return (<Box component="img" src={_.isEmpty(props.rowData.image) ? null : props.rowData.image} sx={{
                                        height: 100,
                                        width: 100
                                    }} />);
                                    case ':delete': return <DeleteRow {...props} />;
                                }
                            }
                        },
                        headCell: {
                            content: (props) => {
                                if (props.column.key === 'addColumn') {
                                    return <AddButton {...props} totalTimePlayed={totalTimePlayed} />;
                                }
                            }
                        }
                    }}
                    dispatch={dispatch}
                />
            </TableContainer>
        </Container>
    );
};

export default SmartChapterTable;
