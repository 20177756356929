import React, { useEffect } from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import Banner from '../components/Banner';
import Benefit from '../components/Benefit';
import PricingComponent from '../components/pricing/Pricing';
// import Feature from '../components/Feature';

function HomePage() {

    useEffect(() => { 
        document.body.style.backgroundImage = 'linear-gradient(#e66465, #9198e5)';
    } , [])

    return (
        <div>
            <AppAppBar />
            <Banner />
            {/* <Benefit /> */}
            {/* <Feature /> */}
            <ProductHero />
            <ProductValues />
            <PricingComponent />
            {/* <ProductCategories />
            <ProductHowItWorks />
            <ProductCTA />
            <ProductSmokingHero />
            // <AppFooter /> */}
        </div>
    );
}

export default HomePage;
