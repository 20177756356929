import { Outlet } from "react-router-dom";
// import './App.css';
import { Helmet } from "react-helmet"
import { CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import React from "react";
import { createTheme, responsiveFontSizes, useTheme } from '@mui/material/styles';

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

declare module '@mui/material/styles' {
  interface Theme {
    themeStatus: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    themeStatus?: {
      danger?: string;
    };
  }
}

function App() {

  // const colorMode = React.useContext(ColorModeContext);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1, width=device-width, maximum-scale=1, user-scalable=no" />
          <title>HyperCatcher Studio</title>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>
        <Outlet />
      </div>
    </React.Fragment>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState<string>('light');

  React.useEffect(() => {
    setMode(window.localStorage.getItem('mode') ?? 'light');
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('mode', mode);
  }, [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  // https://bareynol.github.io/mui-theme-creator/
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode as PaletteMode,
          primary: {
            main: '#ef720b',
            contrastText: '#ffffff',
          },
          secondary: {
            main: '#f50057',
          },
        },
      }),
    [mode],
  );


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
