import { Add } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'
import { toInteger } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PodcastValueBlockFormState, ValueBlockRecipientFormState, FullValueBlockFormState } from './FormStateModel';
import TextField from './modules/components/TextField';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

interface ValueFormBuilderProps {
  podcastValueBlockFormProp: any,
  podcastValueBlockFormUpdate: any,
  valueBlockFormRecipientsFormProp: any,
  podcastValueBlockRecipientFormUpdate: any
}


const ValueFormBuilder: React.FC<ValueFormBuilderProps> = (props) => {

  const { podcastValueBlockFormProp, podcastValueBlockFormUpdate, valueBlockFormRecipientsFormProp, podcastValueBlockRecipientFormUpdate } = props;

  const [valueBlockFields, setValueBlockFields] = useState<PodcastValueBlockFormState>(podcastValueBlockFormProp);
  const [valueBlockRecipientFields, setValueBlockRecipient] = useState<ValueBlockRecipientFormState>({fields: valueBlockFormRecipientsFormProp});

  useEffect(() => {
    podcastValueBlockFormUpdate(valueBlockFields);
    podcastValueBlockRecipientFormUpdate(valueBlockRecipientFields.fields);
  });

  const handleValueBlockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValueBlockFields = Object.entries(valueBlockFields!).map((field) => {
      if (field[0] === event.target.name) {
        return [field[0], event.target.value];
      };
      return field;
    });
    setValueBlockFields({  type: newValueBlockFields[newValueBlockFields.findIndex((item) => item.includes("type"))][1], 
    method: newValueBlockFields[newValueBlockFields.findIndex((item) => item.includes("method"))]![1], 
    suggested: newValueBlockFields[newValueBlockFields.findIndex((item) => item.includes("suggested"))]![1] });
  }

  const handleValueBlockRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    valueBlockRecipientFields.fields[event.target.tabIndex][event.target.name] = event.target.value;
    // @ts-ignore
    setValueBlockRecipient({fields: valueBlockRecipientFields.fields});
  }

  const handeDeleteField = (event: React.ChangeEvent<any>) => {
    // @ts-ignore
    valueBlockRecipientFields.fields.splice(event.target.tabIndex, 1);
    // @ts-ignore
    setValueBlockRecipient({fields: valueBlockRecipientFields.fields});
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const submissionForm: FullValueBlockFormState = new FullValueBlockFormState(valueBlockFields, valueBlockRecipientFields);

    console.log(JSON.stringify(submissionForm));
    // Send the form data to your server and save it
    // Then, you can return a JSON response to the client
    fetch('/submit-form', {
      method: 'POST',
      body: JSON.stringify(submissionForm)
    })
      .then(response => response.json())
      .then(data => {
        // Do something with the JSON response
        console.log(data);
      });
  }

  const addField = () => {
    const newFields = valueBlockRecipientFields.fields.concat(
      { name: '', type: '', address: '', split: '', customValue: '', customKey: '' });
    setValueBlockRecipient({ fields: newFields });
  }

  return (
    <Grid2 container spacing={2} >
    <form onSubmit={handleSubmit}>
    <label>Value Block</label>
    <Grid2 container spacing={2} >
      {Object.entries(valueBlockFields).map(field => (
        <Grid2 xs={3}>
            <TextField
              type="text"
              name={field[0]}
              label={field[0]}
              value={field[1]}
              onChange={handleValueBlockChange}
            />
        </Grid2>
      ))}
      </Grid2>
      
      {Object.entries(valueBlockRecipientFields.fields).map(field => (

        <><label>{`Recipient ${toInteger(field[0]) + 1} `} </label><Grid2 container columns={14} spacing={2}>
          <Grid2 xs={2}>
            <TextField
              type="text"
              name='name'
              label='name'
              value={field[1].name}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={2}>
            <TextField
              type="text"
              label='type'
              name='type'
              value={field[1].type}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={2}>
            <TextField
              type="text"
              name='address'
              label='address'
              value={field[1].address}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={2}>
            <TextField
              type="text"
              name='split'
              label='split'
              value={field[1].split}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={2}>
            <TextField
              type="text"
              name='customKey'
              label='customKey'
              value={field[1].customKey}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={3}>
            <TextField
              type="text"
              name='customValue'
              label='customValue'
              value={field[1].customValue}
              inputProps={{ tabIndex: +field[0] }}
              onChange={handleValueBlockRecipientChange} />
          </Grid2>
          <Grid2 xs={1}>
            <IconButton tabIndex={+field[0]} onClick={handeDeleteField} > 
              <RemoveCircleOutlineOutlinedIcon /> 
            </IconButton>
          </Grid2>
        </Grid2></>
      ))}

      <br />
      <Grid2 container spacing={2} >
      <Grid2>
      <Button variant="outlined" type="button" onClick={addField}>Add Field</Button>
      </Grid2>
      </Grid2>
    </form>
    </Grid2>
  );
};

export default ValueFormBuilder;