// import "./Demo.scss";

import React, { useEffect, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { ITableProps, kaReducer, Table } from "ka-table";
import {
    loadData, updateData, deleteRow, hideNewRow, saveNewRow, insertRow, updateCellValue, closeEditor, clearFocused, moveFocusedDown, moveFocusedLeft, moveFocusedRight, moveFocusedUp, openEditor,
    setFocused, updatePageIndex, updateSortDirection,
} from "ka-table/actionCreators";
import { ICellEditorProps, ICellTextProps, IHeadCellProps } from 'ka-table/props';
import {
    DataType,
    SortDirection,
    SortingMode,
    EditingMode
} from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { TableContainer, AppBar, Container, Box, Typography, TextField, Link, Button, Paper, Checkbox } from '@mui/material';
import { fixupChapters, uuidv4, getTimeStringFromSecs } from "../chapterHelper";
import { kaPropsUtils } from 'ka-table/utils';
import SmartChapter from "../models/smartChapter";
import { getSecsFromFormattedTimeString } from '../chapterHelper';
import "ka-table/style.css";

const _ = require('lodash');
interface ITimeAwareHeadCellProps extends IHeadCellProps {
    totalTimePlayed: number,
}
interface IHCCellEditorProps extends ICellEditorProps {
    smartChapters: Array<SmartChapter>
}

interface IChapterTableProps extends ITableProps {
    jumpToChapter: (time: number) => any
}

const DeleteRow: React.FC<ICellTextProps> = ({ dispatch, rowKeyValue }) => {
    return (
        <Button
            onClick={() => dispatch(deleteRow(rowKeyValue))}
        >x</Button>
    );
};

const SaveButton: React.FC<ICellEditorProps> = ({
    dispatch
}) => {
    const saveNewData = () => {
        const rowKeyValue = uuidv4();
        dispatch(saveNewRow(rowKeyValue, {
            validate: true
        }));
    };
    return (
        <div className='buttons'>
            <Button variant="outlined"
                onClick={saveNewData}
            >s</Button>
            <Button
                onClick={() => dispatch(hideNewRow())}
            >c</Button>
        </div>
    );
};

const CustomLookupEditor: React.FC<IHCCellEditorProps> = ({
    column, dispatch, rowKeyValue, value, smartChapters
}) => {    
    const close = () => {
        dispatch(closeEditor(rowKeyValue, column.key));
    };
    let options = Array<JSX.Element>();
    if (smartChapters) {
        for (let i = 0; i < smartChapters.length; i++) {
            options.push(<option value={smartChapters[i].name}>{smartChapters[i].name}</option>)
        }
        options.push(<option value={'none'}>{'none'}</option>)
    }
    const [editorValue, setValue] = useState(value);

    return (
        <div>
            <select
                className='form-control'
                autoFocus={true}
                defaultValue={editorValue}
                onBlur={(event) => {
                    close();
                }}
                onChange={(event) => {
                    if (event.target.selectedIndex >= smartChapters.length) {
                        dispatch(updateCellValue(rowKeyValue, column.key, ''));
                        dispatch(updateCellValue(rowKeyValue, 'title', ''));
                        dispatch(updateCellValue(rowKeyValue, 'img', ''));
                        dispatch(updateCellValue(rowKeyValue, column.key, ''));
                        dispatch(updateCellValue(rowKeyValue, 'url', ''));
                    } else {
                        dispatch(updateCellValue(rowKeyValue, column.key, smartChapters[event.target.selectedIndex].name));
                        dispatch(updateCellValue(rowKeyValue, 'title', smartChapters[event.target.selectedIndex].title));
                        dispatch(updateCellValue(rowKeyValue, 'img', smartChapters[event.target.selectedIndex].image));
                        dispatch(updateCellValue(rowKeyValue, column.key, smartChapters[event.target.selectedIndex].name));
                        dispatch(updateCellValue(rowKeyValue, 'url', smartChapters[event.target.selectedIndex].url));
                    }
                }}>
                {options ? options : null}
            </select>
        </div >
    );
};


const tablePropsInit: IChapterTableProps = {
    jumpToChapter: (time: number) => { },
    columns: [
        { dataType: DataType.String, key: "title", title: "Title", width: 100 },
        { dataType: DataType.String, key: "url", title: "Url", width: 100 },
        { dataType: DataType.String, key: "img", title: "Image", width: 100 },
        { dataType: DataType.String, key: "formattedStartTimeInSec", title: "Start Time", sortDirection: SortDirection.Ascend, width: 125 },
        { dataType: DataType.String, key: "smartChapters", title: "Smart Chapters", visible: true, width: 100 },
        { dataType: DataType.Number, key: "startTimeInSec", title: "startTimeInSec", visible: false },
        { dataType: DataType.Number, key: "endTimeInSec", title: "endTimeInSec", visible: false },
        { dataType: DataType.String, key: "elementId", title: "elementId", visible: false },
        { dataType: DataType.Boolean, key: "toc", title: "TOC", width: 50 },
        { title: "Action", key: ':delete', style: { textAlign: 'center' }, isEditable: false, width: 100 },
    ],
    data: [], // Add this line to initialize with empty array
    validation: ({ column, value }) => {
        if (column.key === 'formattedStartTimeInSec') {
            if (!value) {
                return `Value can't be empty`;
            }
            if (!value.match(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/)) {
                return `Value must be in the form of HH:MM:SS (e.g. 00:01:01)`;
            }
        }
    },
    singleAction: loadData(),
    editingMode: EditingMode.Cell,
    sortingMode: SortingMode.Single,
    rowKeyField: "elementId",
    columnResizing: true
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Press Start 2P',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const ChapterTable: React.FC<any> = (props: any) => {
    const [tableProps, changeTableProps] = useState({
        ...tablePropsInit,
        data: [] // Ensure data is initialized as empty array
    });

    useEffect(() => {
        // Anything in here is fired on component mount.
        if (props.fabButtonRef) {
            const button = props.fabButtonRef.current;
    
            // Attach an event listener to the button
            button.addEventListener('click', () => {
                let keyVal = uuidv4();
                    const newRow = {
                        title: '',
                        elementId: keyVal,
                        formattedStartTimeInSec: getTimeStringFromSecs(totalTimePlayed)
                    };
                    dispatch(insertRow(newRow, { rowKeyValue: keyVal }))
            });
        }    
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, [])

    const totalTimePlayed: number = Math.ceil(props.totalTimePlayed);

    const [chapters, setChapters] = useState([]);
    let smartChapters: Array<SmartChapter> = [];
    
    // Initialize smartChapters from props
    if (props.studioPodcastDetail?.smartChapters) {
        smartChapters = props.studioPodcastDetail.smartChapters;
    }

    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevProps: ITableProps) => {
            const newProps = kaReducer(prevProps, action);
            return {
                ...newProps,
                data: newProps.data || [] // Ensure data is always an array
            };
        });
        
        if (action.columnKey == 'formattedStartTimeInSec' && action.type == 'UpdateCellValue') {
            const secs = getSecsFromFormattedTimeString(action.value ?? '00:00:00');
            dispatch(updateCellValue(action.rowKeyValue, 'startTimeInSec', secs));
        }
    };

    // Handle updates to chapters from props
    useEffect(() => {
        if (props.chapters && !_.isEqual(props.chapters, chapters)) {
            const fixedChapters = fixupChapters(props.chapters);
            setChapters(fixedChapters);
            dispatch(updateData(fixedChapters || [])); // Ensure data is always an array
        }
    }, [props.chapters]);

    // Handle updates to tableProps.data
    useEffect(() => {
        if (tableProps.data) {
            props.getLatestChapters(kaPropsUtils.getData(tableProps));
        }
    }, [tableProps.data]);

    tableProps.jumpToChapter = props.jumpToChapter;
    return (

        <Container maxWidth="xl" className="custom-theme-demo">
            <TableContainer component={Paper}>
                <Table
                    {...tableProps}
                    dispatch={dispatch}
                    childComponents={{
                        table: {
                            elementAttributes: () => ({
                                className: 'MuiTable-root',
                                style: {
                                    backgroundColor: props.theme.palette.background.default
                                }
                            })
                        },
                        tableHead: {
                            elementAttributes: () => ({
                                className: 'MuiTableHead-root',
                                style: {
                                    backgroundColor: props.theme.palette.background.default,
                                    color: props.theme.palette.text.primary
                                }
                            })
                        },
                        cellEditor: {
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'addColumn': return <SaveButton {...props} />;
                                    case 'smartChapters': return <CustomLookupEditor {...props} smartChapters={smartChapters} />;
                                }
                            }
                        },
                        cellText: {
                            elementAttributes: () => ({
                                className: 'MuiTableHead-root',
                                style: {
                                    backgroundColor: props.theme.palette.background.default,
                                    color: props.theme.palette.text.primary
                                }
                            }),
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'url': return <BootstrapInput multiline id="fullWidth" rows={4} value={props.rowData.url} />;
                                    case 'img': return <Box component="img" src={_.isEmpty(props.rowData.img) ? null : props.rowData.img} sx={{
                                        height: 100,
                                        width: 100
                                    }} />;
                                    case 'smartChapters': return _.isEmpty(props.rowData.smartChapters) ? (<label>Select Smart Chapter</label>) : null;
                                    case ':delete': return <div> <DeleteRow {...props} /> <Button onClick={ () => tableProps.jumpToChapter(props.rowData.startTimeInSec)}>Jump</Button> </div>;
                                    case 'toc':
                                        return (
                                            <Checkbox 
                                            checked={props.rowData.toc !== false}
                                            onChange={() => {
                                                const newTocValue = props.rowData.toc === undefined ? false : !props.rowData.toc;
                                                dispatch(updateCellValue(props.rowKeyValue, 'toc', newTocValue));
                                            }}
                                            />
                                        );
                                }
                            }
                        },
                        headCell: {
                            elementAttributes: () => ({
                                className: 'MuiTableHead-root',
                                style: {
                                    backgroundColor: props.theme.palette.mode == 'light' ? props.theme.palette.grey['50'] : props.theme.palette.grey['A700'],
                                    color: props.theme.palette.text.primary
                                }
                            }),
                        },
                        cell: {
                            elementAttributes: ({ column, rowKeyValue, isEditableCell }) => {
                                if (isEditableCell) return undefined;

                                const cell = { columnKey: column.key, rowKeyValue }
                                const isFocused = cell.columnKey === tableProps.focused?.cell?.columnKey
                                    && cell.rowKeyValue === tableProps.focused?.cell?.rowKeyValue;
                                return {
                                    tabIndex: 0,
                                    ref: (ref: any) => isFocused && ref?.focus(),
                                    onKeyUp: (e) => {
                                        switch (e.keyCode) {
                                            case 39: dispatch(moveFocusedRight({ end: e.ctrlKey })); break;
                                            case 37: dispatch(moveFocusedLeft({ end: e.ctrlKey })); break;
                                            case 38: dispatch(moveFocusedUp({ end: e.ctrlKey })); break;
                                            case 40: dispatch(moveFocusedDown({ end: e.ctrlKey })); break;
                                            case 13:
                                                dispatch(openEditor(cell.rowKeyValue, cell.columnKey));
                                                dispatch(setFocused({ cellEditorInput: cell }));
                                                break;
                                        }
                                    },
                                    onFocus: () => !isFocused && dispatch(setFocused({ cell: { columnKey: column.key, rowKeyValue } })),
                                    onKeyDown: (e) => e.keyCode !== 9 && e.preventDefault(),
                                    onBlur: () => isFocused && dispatch(clearFocused())
                                }
                            },
                        },
                    }}
                />
            </TableContainer>
        </Container>

    );
};

export default ChapterTable;
