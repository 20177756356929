import { Component } from "react";
import { withRouter } from './withRouter';
import { Container, Box, Typography, Breadcrumbs, Link, Button, Stack, TextField } from '@mui/material';
import { makeAuthenticatedRequest } from '../utils/api';
import HCAppBar from '../HCAppBar';

interface IProps {
    navigate: any
}

interface IState {
    rssUrl: string
}

class DashboardAddNewPodcast extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            rssUrl: ''
        }
    }

    handleAddNewPodcast = async () => {
        try {
            const response = await makeAuthenticatedRequest('/api/studio/podcast/create', {
                method: 'POST',
                body: {
                    'rssUrl': this.state.rssUrl
                }
            });
            
            if (response != null) {
                this.props.navigate(`/dashboard`);
            }
        } catch (error) {
            console.error('Error adding new podcast:', error);
        }
    }

    handleRssUrlChange = (event: any) => {
        this.setState({ rssUrl: event.target.value });
    }

    render() {
        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <HCAppBar toggleColorMode={this.context.toggleColorMode} />

                <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
                    <Breadcrumbs >
                        <Link href="/dashboard"><Typography> Home </Typography></Link>
                        <Typography>Add New Podcast</Typography>
                    </Breadcrumbs>

                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Stack spacing={2}>
                            <TextField
                                id="rss-url"
                                label="RSS URL"
                                variant="outlined"
                                value={this.state.rssUrl}
                                onChange={this.handleRssUrlChange}
                            />
                            <Button variant="outlined" onClick={() => { this.handleAddNewPodcast() }}>Add New Podcast</Button>
                        </Stack>
                    </Container>
                </Container>
            </Box>
        )
    }
}

export default withRouter(DashboardAddNewPodcast);
