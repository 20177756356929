
import { Component, SyntheticEvent } from 'react';
import { ListItemButton, ListItemText, ListItemIcon, Pagination, Container, Paper, Box, Chip, Skeleton } from '@mui/material';
import Episode from './models/episode';
import Grid2 from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import DoneIcon from '@mui/icons-material/Done';

interface IProps {
    episodes: Array<Episode> | null,
    handleItemClicked: any
}

interface IState {
    episodes: Array<Episode> | null,
    selectedIndex: string | number | undefined
}

class EpisodeList extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedIndex: 0,
            episodes: null
        };
    }

    render() {
        if (this.props.episodes != null) {
            return (
                <Container>
                    {this.buildEpisodeList(this.state.selectedIndex as number)}
                    <Pagination onChange={this.onPaginationSelect} />
                </Container>
            );
        }
        return (
            <Container>
            <Grid2 container spacing={2} >
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        </Grid2>
        </Container>
        );
    }

    totalPages() {
        if (this.props.episodes && this.props.episodes.length) {
            return Math.floor(this.props.episodes.length / 10) + 1;
        }
        return 0;
    }

    onPaginationSelect = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ selectedIndex: value - 1 });
    }

    buildEpisodeList(selectedIndex: number) {
        return (
            <Grid2 container spacing={2} >
            {
            this.props.episodes?.sort(function (a: Episode, b: Episode) {
                if (a.pubDateTime > b.pubDateTime) return -1;
                if (a.pubDateTime < b.pubDateTime) return 1;
                return 0;
            }).map((item: any, index: number) => {
                if ((index >= (selectedIndex * 10)) && (index < (selectedIndex + 2) * 10)) {
                    return (
                    <Grid2 xs={9}> 
                    <Paper elevation={3} >
                    <ListItemButton key={item.title} onClick={(e) => {this.props.handleItemClicked(e, item);}}>
                    <Box component="img" src={_.isEmpty(item.imageUrl) ? null : item.imageUrl} sx={{
                                    height: 100,
                                    width: 100,
                                    mr: 4
                                }}>
                    </Box>
                        <ListItemText className='nes-text' key={item.title}>{item.title}</ListItemText>
                    </ListItemButton>
                    <Chip label={`chapters: ${item.chapters?.length}`} variant="outlined" sx={{m:2}} />
                    { item.transcriptUrl ? <Chip label={"Transcript"} icon={<DoneIcon />} color="success" variant="outlined" sx={{m:2}} /> : null }
                    </Paper>
                    </Grid2>
                    );
                }})
            }
            </Grid2>
            );
        }
}

export default EpisodeList;