import './player.scss';

function Footer({ children }: { children: any }) {
    return (
        <div>
            <div className="phantom" />
            <div className="footerStyle">
                {children}
            </div>
        </div>
    )
}

export default Footer;