import { getTimeStringFromSecs } from "../chapterHelper";

class Chapter {

    title: string | null
    startTimeInSec: number
    endTimeInSec: number
    url: string | null;
    img: string | null;
    elementId: string;
    formattedStartTimeInSec: string | null;
    smartChapters: string | null;
    toc: boolean | null;

    constructor(title: string | null, startTimeInSec: number, endTimeInSec: number, url: string | null, image: string | null, elementId: string, formattedStartTimeInSec: string | null, smartChapters: string | null, toc: boolean | true) {
        this.title = title;
        this.startTimeInSec = startTimeInSec;
        this.endTimeInSec = endTimeInSec;
        this.url = url;
        this.img = image;
        this.elementId = elementId;
        this.toc = toc;
        if (formattedStartTimeInSec) {
            this.formattedStartTimeInSec = formattedStartTimeInSec;
        } else {
            const startTime = startTimeInSec ?? 0;
            this.formattedStartTimeInSec = getTimeStringFromSecs(startTime);
        }
        
        this.smartChapters = smartChapters;
    }
}

export default Chapter;