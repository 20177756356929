import React from 'react';
import './ImageComponent.css'; // import the CSS file

type ImageComponentProps = {
  imgUrl: string;
}

function ImageComponent({imgUrl}: ImageComponentProps) {
  return (
    <div>
      <img className="imageAnimation" src={imgUrl} alt="example" />
    </div>
  );
}

export default ImageComponent;
