//@ts-nocheck
import Chapter from "./models/chapter"
const _ = require('lodash');

export const getSecsFromFormattedTimeString = (timeStr: any) => {
    const [h, m, s] = timeStr.split(':')
    return parseInt(h) * 3600 + parseInt(m) * 60 + parseInt(s)
}

export const updateChapterTimeStrings = (chapters: Chapter[]) => {
    chapters.forEach(chapter => {
        const secs = getSecsFromFormattedTimeString(chapter.formattedStartTimeInSec);
        chapter.startTimeInSec = secs;
    });
    return chapters;
}

export const getTimeStringFromSecs = (secs: int): string => {
    return new Date(secs * 1000).toISOString().substr(11, 8);
}

export const fixupChapters = (chapters: Chapter[]): any => {
    for (let i = 0; i < chapters?.length; i++) {
        chapters[i].startTimeInSec = Math.round(chapters[i].startTimeInSec);
        if ((i + 1) < chapters?.length) {
            chapters[i].endTimeInSec = Math.round(chapters[i + 1].startTimeInSec) - 1;
        } else {
            chapters[i].endTimeInSec = Math.round(chapters[i].startTimeInSec) + 30
        }
    }
    return chapters;
}

export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const convertEpisodeChaptersJsonToEpisodeDetailObject = (episodeChapters: any): any => {
    const rawChapters = episodeChapters.chapters.map(chapter => {
        return {
            title: chapter.title,
            startTimeInSec: chapter.startTime,
            url : chapter.url,
            img: chapter.img,
            elementId: uuidv4()
        }
    });
    const episodeDetail = {
        chapters: rawChapters.map(chapter => {
            return new Chapter(chapter.title, chapter.startTimeInSec, null, chapter.url, chapter.img, chapter.elementId);
        })
    }
    return episodeDetail;
}

export const cleanChapters = (chapters: Chapter[] | null) => {
    if (chapters != null) {
    let cleanedChapters: Chapter[] = [];
    for (let i = 0; i < chapters.length; i++) {
      let chapter = chapters[i];
      if (chapter.title !== '') {
        cleanedChapters.push(chapter);
      }
    }
    return cleanedChapters;
  }
  return null;
}

export const updateAllChapterStartTimesToMatchFormattedTime = (chapters: Chapter[] | null) => {
    if (chapters != null) {
    let fixedStartTimeChapters: Chapter[] = [];
    for (let i = 0; i < chapters.length; i++) {
      let chapter = chapters[i];
      chapter.startTimeInSec = getSecsFromFormattedTimeString(chapter.formattedStartTimeInSec);
      fixedStartTimeChapters.push(chapter);
    }
    return fixedStartTimeChapters;
  }
  return null;
}

export const checkForChaptersWithSameStartTimeInSec = (chapters: Chapter[] | null): number|null => {
    let startTimeSet = new Set();
    for (let i = 0; i < chapters.length; i++) {
        if (startTimeSet.has(chapters[i].startTimeInSec)) {
            return i;
        }
        startTimeSet.add(chapters[i].startTimeInSec);
    }
    return null;
}

export const checkForChaptersWithSameStartTime = (chapters: Chapter[] | null): number|null => {
    let startTimeSet = new Set();
    for (let i = 0; i < chapters.length; i++) {
        if (startTimeSet.has(chapters[i].startTime)) {
            return i;
        }
        startTimeSet.add(chapters[i].startTime);
    }
    return null;
}