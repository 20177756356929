import { ColorModeContext } from '../App';
import { Component, createRef, useRef } from 'react';
import { Tooltip, Stack, AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Toolbar, IconButton, Alert, Grid, CircularProgress, Fab, makeStyles, Theme, CircularProgressProps } from '@mui/material';
import AudioPlayer from 'material-ui-audio-player';
import Footer from '../Footer';
import Episode from '../models/episode';
import Chapter from '../models/chapter';
import { withRouter } from './withRouter.js';
import { getLoggedInUser } from '../utils/firebase.js';
import EpisodeDetail from '../models/episodeDetail';
import ChapterTable from './chapterTableBuilder';
import CommunityChapterTable from './communityChapterTableBuilder';
import { getBucketUrlForEpisode } from '../cloudStorageHelper'
import Podcast from '../models/podcast';
import { podcastSpecJsonChaptersFromHyperChapters } from '../PodcastChapterUtil';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { ThemeProvider } from '@emotion/react';
import ResultModel from '../models/resultModel';
import { checkForChaptersWithSameStartTimeInSec, convertEpisodeChaptersJsonToEpisodeDetailObject, updateAllChapterStartTimesToMatchFormattedTime } from '../chapterHelper';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { cleanChapters } from '../chapterHelper';
import AddIcon from '@mui/icons-material/Add';
import CircularProgressWithLabel from './circularLoadingSpinner';
import HCAppBar from '../HCAppBar';
import { makeAuthenticatedRequest } from '../utils/api';
const _ = require('lodash');

interface showTranscriptLoadingSpinnerInterface {
  shouldShow: boolean,
  progress: number
}

interface IProps {
  params_url: string | null,
  podcastId: any,
  location: any,
  episodeId: string,
  theme: any,
  selectedEpisode: any
}

interface IState {
  url: string | null,
  pip: boolean,
  playing: boolean,
  volume: number,
  muted: boolean,
  played: number,
  loaded: number,
  duration: number,
  playbackRate: number,
  loop: boolean,
  urlInput: string | null,
  seeking: boolean,
  episodes: Array<Episode> | null,
  title: string | null,
  guid: string | null,
  chapters: Array<Chapter> | null,
  communityChapters: Array<Chapter> | null,
  image: string,
  chapter_form_title: string,
  chapter_form_url: string,
  chapter_form_image: string,
  episodeDetail: EpisodeDetail | null,
  bucketUrlForEpisodeChapters: string | null
  episode: any | null,
  studioPodcastDetail: Podcast | null
  podcastId: string,
  episodeId: string,
  theme: any,
  alert: ResultModel | null,
  suggestedChaptersAlert: ResultModel | null,
  showLoadingSpinner: boolean,
  showTranscriptLoadingSpinner: showTranscriptLoadingSpinnerInterface,
}

class ChapterEditor extends Component<IProps, IState>  {
  auth: any;
  player: HTMLAudioElement | null | undefined;
  timer: NodeJS.Timer | null = null;

  constructor(props: IProps) {
    super(props);
    this.componentCleanup = this.componentCleanup.bind(this);

    this.state = {
      url: null,
      pip: false,
      playing: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      urlInput: this.props.params_url ?? null,
      seeking: false,
      episodes: null,
      title: null,
      guid: null,
      chapters: null,
      communityChapters: null,
      image: '',
      chapter_form_title: '',
      chapter_form_url: '',
      chapter_form_image: '',
      episodeDetail: null,
      bucketUrlForEpisodeChapters: getBucketUrlForEpisode(this.props.podcastId, this.props.episodeId) ?? '',
      episode: null,
      podcastId: this.props.podcastId,
      episodeId: this.props.episodeId,
      studioPodcastDetail: null,
      theme: this.props.theme,
      alert: null,
      suggestedChaptersAlert: null,
      showLoadingSpinner: false,
      showTranscriptLoadingSpinner: {shouldShow: false, progress: 0},
    }
    this.handleInitialLoad();
  }

  async handleSuggestChapters() {
    if (_.isEmpty(this.state.episodeDetail?.podcast_transcript_url)) {
        const errorMessage = 'You need to add a .srt transcript to your RSS Feed to receive chapter suggestions';
        const isError = true;
        const resultModel = new ResultModel(errorMessage, 500, isError);
        this.setState({ 'suggestedChaptersAlert': resultModel });
        return;
    }

    try {
        this.setState({ 'showLoadingSpinner': true });
        const result = await makeAuthenticatedRequest('/api/podcast/suggestedChaptersTriggers/suggestChapters', {
            method: 'POST',
            body: {
                'podcastId': this.state.podcastId,
                'episodeId': this.state.episodeId,
                'transcript_url': this.state.episodeDetail?.podcast_transcript_url,
                'triggers': this.state.studioPodcastDetail?.triggers
            }
        });
        
        this.setState({ 'showLoadingSpinner': false });
        const errorMessage = 'Suggested Chapters Updated (please refresh)';
        const resultModel = new ResultModel(errorMessage, 200, false);
        this.setState({ 'suggestedChaptersAlert': resultModel });
    } catch (error) {
        this.setState({ 'showLoadingSpinner': false });
        const errorMessage = 'Error updating chapters';
        const resultModel = new ResultModel(errorMessage, 500, true);
        this.setState({ 'suggestedChaptersAlert': resultModel });
    }
  }

  async handlePollingForTranscriptStatus() {
    try {
        const result = await makeAuthenticatedRequest('/api/podcast/transcript/progress', {
            method: 'POST',
            body: {
                'podcastId': this.state.podcastId,
                'episodeId': this.state.episodeId,
            }
        });
        
        if (result.transcription_progress >= 0 && result.transcription_progress < 100) {
            this.setState({ 'showTranscriptLoadingSpinner': {shouldShow: true, progress: result.transcription_progress}} );  
        } else {
            this.setState({ 'showTranscriptLoadingSpinner': {shouldShow: false, progress: 0 }} );
            this.stopTimer();
        }
    } catch (error) {
        this.setState({ 'showTranscriptLoadingSpinner': { shouldShow: false, progress: 0 }} );
        this.stopTimer();
    }
  }

  async handleDeleteCommunityChapters(chapterGuids: any, podcastId: string, episodeId: string) {
    try {
        await makeAuthenticatedRequest('/api/podcast/cloudChapters/delete', {
            method: 'POST',
            body: {
                'chapterGuids': chapterGuids,
                'podcastId': podcastId,
                'episodeId': episodeId,
            }
        });
    } catch (error) {
        console.error('Error deleting community chapters:', error);
    }
  }

  async handleInitialLoad() {
    await this.getStudioEpisode();
    await this.getStudioPodcast();
    await this.handlePollingForTranscriptStatus();
    if (this.state.studioPodcastDetail?.rssUrl && this.state.episodeDetail?.title) {
      this.getEpisodeDetails(this.state.studioPodcastDetail!.rssUrl, this.state.episodeDetail!.title);
    }
    this.timer = setInterval(()=> this.handlePollingForTranscriptStatus(), 30000);
  }

  componentDidMount(): void {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  componentCleanup() { 
    this.stopTimer();
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = null;
  }

  async setupPlayer(player: HTMLAudioElement | null) {
    this.player = player;
    this.player!.addEventListener('timeupdate', () => {
      this.setState({ duration: this.player!.currentTime });
    });
  }

  getLatestChapters = (chapterResult: any) => {
    console.log('get latest chapters');
    console.log(chapterResult);
    if (!_.isEqual(chapterResult, this.state.chapters)) {
      this.setState({ chapters: chapterResult });
    }
  }

  jumpToChapter = (time: number) => {
    if (this.player) {
      this.player!.currentTime = time;
    }
  }

  updateWithCommunityChapters = (selectedCommunityChapters: Chapter[], shouldSave: boolean = true) => {
    let chapters = [...this.state.chapters ?? []].concat(selectedCommunityChapters);
    if (this.state.episodeDetail) {
      var episodeDetail = { ...this.state.episodeDetail }
      episodeDetail.chapters = chapters;
      this.setState({ episodeDetail: episodeDetail });
    }
    if (shouldSave) {
      this.handleUpdateChapters();
    }
    let chapterGuids = selectedCommunityChapters!.map((chapter: Chapter) => chapter.elementId );
    this.handleDeleteCommunityChapters(chapterGuids, this.state.podcastId, this.state.episodeId);
  }

  getLatestCommunityChapters = (communityChaptersResult: any) => {
    if (!_.isEqual(communityChaptersResult, this.state.communityChapters)) {
      this.setState({ communityChapters: communityChaptersResult });
    }
  }

  acceptAllCommunityChapters = async () => {
    if (!_.isEmpty(this.state.communityChapters)) {
      this.updateWithCommunityChapters(this.state.communityChapters!, false);
      let chapterGuids = this.state.communityChapters!.map((chapter: Chapter) => chapter.elementId );
      await this.handleDeleteCommunityChapters(chapterGuids, this.state.podcastId, this.state.episodeId);
    }
    var episodeDetail = { ...this.state.episodeDetail };
    episodeDetail.community_chapters = '';
    this.setState({ 'episodeDetail': (episodeDetail as EpisodeDetail) });
    this.handleUpdateChapters();
  }

  // episodeTitle should be the episode GUID, but this will do for now
  getEpisodeDetails = async (rssUrl: string | null, episodeTitle: string | null) => {
    try {
        const response = await makeAuthenticatedRequest('/api/studio/episode', {
            method: 'POST',
            body: {
                'rssUrl': rssUrl,
                'episodeTitle': episodeTitle
            }
        });
        
        this.setState({
            episode: new Episode(response.title, response.url, response.guid, response.pubDateTime)
        });
    } catch (error) {
        console.error('Error fetching episode details:', error);
    }
  }

  getStudioEpisode = async () => {
    try {
        const response = await makeAuthenticatedRequest('/api/studio/editEpisode', {
            method: 'POST',
            body: {
                'episodeId': this.props.episodeId,
                'podcastId': this.props.podcastId
            }
        });
        
        if (response != null) {
            let episodeDetail = EpisodeDetail.initFromJson(response);
            this.setState({
                episodeDetail: episodeDetail
            });
        }
    } catch (error) {
        console.error('Error fetching studio episode:', error);
    }
  }

  getStudioPodcast = async () => {
    try {
      const response = await makeAuthenticatedRequest('/api/studio/podcast', {
        method: 'POST',
        body: {
          'podcastId': this.props.podcastId
        }
      });
      
      if (response != null) {
        let podcastDetail = Podcast.initFromJson(response);
        this.setState({
          studioPodcastDetail: podcastDetail
        });
      }
    } catch (error) {
      console.error('Error fetching studio podcast:', error);
    }
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handlePlay = () => {
    // console.log('onPlay')
    this.setState({ playing: true })
  }

  handlePause = () => {
    // console.log('onPause')
    this.setState({ playing: false })
  }


  handleDuration = (duration: number) => {
    console.log('onDuration', duration)
    this.setState({ duration })
  }

  handleUploadChapters = async (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const parsedUploadedChapters = convertEpisodeChaptersJsonToEpisodeDetailObject(JSON.parse(e.target!.result as string));
      console.log(parsedUploadedChapters);
      const newEpisodeDetails: EpisodeDetail = { ...this.state.episodeDetail! };
      newEpisodeDetails.chapters = parsedUploadedChapters.chapters;
      this.setState({ episodeDetail: newEpisodeDetails });
    };    
  }

  handleUpdateChapters = async () => {
    try {
      await makeAuthenticatedRequest('/api/podcast/cloudChapters/update', {
        method: 'POST',
        body: {
          'chapters': this.state.chapters,
          'episodeId': this.state.episodeId,
          'podcastId': this.state.podcastId
        }
      });
      
      const successMessage = 'Chapters updated successfully!';
      const resultModel = new ResultModel(successMessage, 200, false);
      this.setState({ 'alert': resultModel });
    } catch (error) {
      console.error('Error updating chapters:', error);
      const errorMessage = 'Error updating chapters';
      const resultModel = new ResultModel(errorMessage, 500, true);
      this.setState({ 'alert': resultModel });
    }
  }

  handleDownloadJson = async () => {
    let chapters = podcastSpecJsonChaptersFromHyperChapters([...this.state.chapters!]);
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(chapters));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.state.episodeId + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  handlePublishChapters = async () => {
    try {
      await makeAuthenticatedRequest('/api/podcast/cloudChapters/publish', {
        method: 'POST',
        body: {
          'chapters': this.state.chapters,
          'podcastId': this.state.podcastId,
          'episodeId': this.state.episodeId
        }
      });
      
      const successMessage = 'Chapters published successfully!';
      const resultModel = new ResultModel(successMessage, 200, false);
      this.setState({ 'alert': resultModel });
    } catch (error) {
      console.error('Error publishing chapters:', error);
      const errorMessage = 'Error publishing chapters';
      const resultModel = new ResultModel(errorMessage, 500, true);
      this.setState({ 'alert': resultModel });
    }
  }

  handleCopyBucketUrl = () => {
    this.copyTextToClipboard(this.state.bucketUrlForEpisodeChapters ?? '');
  };

  handleGenerateTranscript = async () => {
    if (this.state.studioPodcastDetail == null || this.state.studioPodcastDetail.transcriptTokens === 0) {
        alert("Sorry, no transcript tokens available. Please contact support to get more tokens.");
        return;
    }

    try {
        const result = await makeAuthenticatedRequest('/api/podcast/transcript/generate', {
            method: 'POST',
            body: {
                'url': this.state.episode.url,
                'episodeId': this.props.episodeId,
                'podcastId': this.props.podcastId
            }
        });
        
        const errorMessage = 'Transcript Generation Started!';
        const resultModel = new ResultModel(errorMessage, 200, false);
        this.setState({ 'alert': resultModel });
    } catch (error) {
        const errorMessage = 'Error generating transcript';
        const resultModel = new ResultModel(errorMessage, 500, true);
        this.setState({ 'alert': resultModel });
    }
  }

  fallbackCopyTextToClipboard = (text: string) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text);
}

  handleCopyBucketUrlInputChange = (e: any) => {
    this.setState({ bucketUrlForEpisodeChapters: e.target.value });
  };
  
  buttonRef = createRef<HTMLButtonElement>();

  static contextType = ColorModeContext;
  render() {
    const { episode, duration } = this.state
    return (
      <Box 
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >

      <HCAppBar toggleColorMode={this.context.toggleColorMode} />

        <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
          <Box sx={{ p: 2 }}>
            <Breadcrumbs >
              <Link href="/dashboard"><Typography> Home </Typography></Link>
              <Link href={`/dashboard/${this.props.podcastId}`}><Typography>{`${this.props.podcastId}`}</Typography></Link>
              <Typography>{this.props.episodeId}</Typography>
            </Breadcrumbs>
          </Box>
        
          <Grid container spacing={2} columns={10}>
            <Grid item xs={12}>
              <Typography>Publish Options</Typography>
            </Grid>
            <Grid item xl={2}>
              <Button fullWidth={true} variant="outlined" onClick={() => { this.handleUpdateChapters() }}>Save Changes</Button>
            </Grid>
            <Grid item xl={2}>
              <Button fullWidth={true} variant="outlined" onClick={() => { this.handlePublishChapters() }}>Publish Chapters</Button>
            </Grid>
            <Grid item xl={2}>
              <Button fullWidth={true} variant="outlined" onClick={() => { this.handleDownloadJson() }}>Download Json</Button>
            </Grid>
            <Grid item xl={2}>
            <Button variant="outlined" component="label" fullWidth={true} > Upload Chapters <input hidden multiple type="file" onChange={this.handleUploadChapters}/> </Button>
            </Grid>
              <Grid item xl={2}>
              <Tooltip title={this.state.bucketUrlForEpisodeChapters ?? ''} arrow>
                <Button fullWidth={true} variant="outlined" onClick={() => this.handleCopyBucketUrl()} >
                  Copy Bucket URL
                </Button>
              </Tooltip>  
            </Grid>
              { this.state.alert && <Grid item spacing={10}> <Alert onClose={() => {this.setState({'alert': null})}} severity={this.state.alert.isError ? 'error' : 'success'}>{this.state.alert.message}</Alert></Grid> }
              <Typography sx={{m:2}}>Transcript Token(s): { this.state.studioPodcastDetail ? this.state.studioPodcastDetail.transcriptTokens : 0 } </Typography>
            <Button fullWidth={true} variant="outlined" onClick={() => this.handleGenerateTranscript()} >
                  Generate Transcript
                </Button>
                <Grid item spacing={10}>
                  { this.state.showTranscriptLoadingSpinner.shouldShow && <Alert severity='info'> Generating Transcript... <CircularProgressWithLabel value={this.state.showTranscriptLoadingSpinner.progress} /> </Alert>  }
                </Grid>
          </Grid>

          <section className="topic">
            <h2>Podcast Chapters</h2>
          </section>

          {this.state.episodeDetail
            ? <ChapterTable
              chapters={this.state.episodeDetail?.chapters}
              getLatestChapters={this.getLatestChapters}
              totalTimePlayed={(duration)}
              studioPodcastDetail={this.state.studioPodcastDetail}
              theme={this.props.theme}
              jumpToChapter={this.jumpToChapter}
              fabButtonRef={this.buttonRef}
            />
            :     <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
          
            <Grid item xs={3}>
             <CircularProgress />
            </Grid>   
             
          </Grid> 
          }

          <Container component="span" sx={{ m: 2 }}>
              <Stack spacing={2} direction="row">
                <Button variant="outlined" onClick={() => { this.handleUpdateChapters() }}>Save Changes</Button>
                <Button variant="outlined" onClick={() => { this.acceptAllCommunityChapters() }}>Accept All Suggested Chapters</Button>
              </Stack>
          </Container>

          <section className="topic">
            <h2>Suggested Podcast Chapters</h2>
          </section>

          <Grid container spacing={2} columns={10}>
            <Grid item xs={12}>
            <Button variant="outlined" onClick={() => { this.handleSuggestChapters() }}>Suggest Chapters</Button>
            </Grid>
            <Grid item xl={2}>
            { this.state.episodeDetail?.podcast_transcript_url ? <Alert severity='success'>transcript found!</Alert> : null} 
            </Grid>
            <Grid item xl={2}>
              { this.state.suggestedChaptersAlert && <Alert onClose={() => {this.setState({'suggestedChaptersAlert': null})}} severity={this.state.suggestedChaptersAlert.isError ? 'error' : 'success'}>{this.state.suggestedChaptersAlert.message}</Alert> }
              { this.state.showLoadingSpinner && <Alert severity='info'> Generating Chapters, please wait... <CircularProgress /> </Alert>  }
            </Grid>
            <Grid item xl={12}>
            <Fab color="primary" aria-label="add" ref={this.buttonRef} sx={{position: 'fixed', bottom: 100, right: 100}}>
              <AddIcon />
              </Fab> 
            {this.state.episodeDetail
              ? <CommunityChapterTable
                chapters={this.state.episodeDetail.community_chapters}
                getLatestChapters={this.getLatestCommunityChapters}
                updateWithCommunityChapters={this.updateWithCommunityChapters}
                handleDeleteCommunityChapters={this.handleDeleteCommunityChapters}
                podcastId={this.state.podcastId}
                episodeId={this.state.episodeId}
                handleUpdateChapters={this.handleUpdateChapters}
                theme={this.props.theme}
              />
              : null
            }
              </Grid>
          </Grid>
        </Container>
        <Footer>
          <Grid container spacing={2} columns={12}>
          <Grid item xs={0.5}>
              <IconButton onClick={ () => {this.player!.currentTime = this.player!.currentTime - 5}}>
                <FastRewindIcon />
            </IconButton>
            </Grid>
            <Grid item xs={0.5}>
              <IconButton onClick={ () => {this.player!.currentTime = this.player!.currentTime + 30}}>
                <FastForwardIcon />
            </IconButton>
            </Grid>
            <Grid item xs={11}>
          {this.state.title ? this.state.title : null}
          <ThemeProvider theme={this.state.theme}>
            {/* Styling for audio player won't work until migrating to MUI5 */}
            {episode ? <AudioPlayer src={episode.url!} getPlayer={((player: HTMLAudioElement | null) => this.setupPlayer(player)) }	 /> : null}
          </ThemeProvider>
          </Grid>
          </Grid>
        </Footer>
      </Box >
    )
  }
}

export default withRouter(ChapterEditor);
