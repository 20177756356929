const _ = require('lodash');

export const podcastSpecJsonChaptersFromHyperChapters = (chapters) => {
    let base = {};
    // re-write keys
    chapters.forEach(element => {
        element.startTime = element.startTimeInSec;
        if (_.isEmpty(element.url)) delete element.url;
        if (_.isEmpty(element.img)) delete element.img;
        if (_.isEmpty(element.title)) delete element.title;
        delete element.startTimeInSec;
        delete element.endTimeInSec;
        delete element.elementId;
        delete element.formattedStartTimeInSec;
        delete element.smartChapters;
    });

    base['chapters'] = chapters;
    base['version'] = '1.0.0';
    return base;
}