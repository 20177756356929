import React, { useState } from "react";
import { TableContainer, AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Paper } from '@mui/material';
import { ITableProps, kaReducer, Table } from "ka-table";
import { loadData, updateData, deleteRow, showNewRow } from "ka-table/actionCreators";
import { ICellEditorProps, ICellTextProps, IHeadCellProps } from 'ka-table/props';
import {
    ActionType,
    DataType,
    SortDirection,
    SortingMode,
    EditingMode
} from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { kaPropsUtils } from 'ka-table/utils';
const _ = require('lodash');

const CUSTOM_ACTION_TYPE = "CUSTOM_ACTION_TYPE";
const customAction = (myData: any) => ({
    type: CUSTOM_ACTION_TYPE, // required
    myData // optional payload
});

const SaveButton: React.FC<ICellEditorProps> = ({
    dispatch, rowKeyValue, rowData
}) => {
    return (
        <div className='buttons'>
            <Button
                onClick={() => {
                    dispatch(deleteRow(rowKeyValue));
                    dispatch(customAction({ value: rowData }))
                }}
            >accept</Button>
            <Button
                onClick={() => dispatch(deleteRow(rowKeyValue))}
            >x</Button>
        </div >
    );
};

const tablePropsInit: ITableProps = {
    columns: [
        { dataType: DataType.String, key: "title", title: "Title", width: 250 },
        { dataType: DataType.Number, key: "startTimeInSec", title: "startTimeInSec", visible: false },
        { dataType: DataType.Number, key: "endTimeInSec", title: "endTimeInSec", visible: false },
        { dataType: DataType.String, key: "formattedStartTimeInSec", title: "Start Time", sortDirection: SortDirection.Ascend, width: 250 },
        { dataType: DataType.String, key: "img", title: "Image", width: 250 },
        { dataType: DataType.String, key: "smartChapters", title: "smartChapters", visible: false },
        { dataType: DataType.String, key: "url", title: "Url", width: 250 },
        { dataType: DataType.String, key: "elementId", title: "elementId", visible: false },
        { title: "Actions", key: 'editColumn', style: { width: 250 }, isEditable: false },
    ],
    singleAction: loadData(),
    editingMode: EditingMode.Cell,
    sortingMode: SortingMode.Single,
    rowKeyField: "elementId"
};

const CommunityChapterTable: React.FC<any> = (props: any) => {
    const [tableProps, changeTableProps] = useState(tablePropsInit);
    const [chapters, setChapters] = useState([]);
    const dispatch: DispatchFunc = (action) => {
        if (action.type === ActionType.LoadData) {
            dispatch(updateData(props.chapters));
        }
        if (action.type === CUSTOM_ACTION_TYPE) {
            props.updateWithCommunityChapters([action.myData.value]);

        }
        if (action.type === ActionType.DeleteRow) {
            props.handleDeleteCommunityChapters([action.rowKeyValue], props.podcastId, props.episodeId, props.chapters);
        }

        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    };

    if (!_.isEqual(props.chapters, chapters)) {
        setChapters(props.chapters);
        dispatch(updateData(props.chapters));
    }

    props.getLatestChapters(kaPropsUtils.getData(tableProps));

    return (
        <Container maxWidth="xl">
            <TableContainer component={Paper}>
                <Table
                    {...tableProps}
                    childComponents={{
                        table: {
                            elementAttributes: () => ({
                                className: 'MuiTable-root',
                                style: {
                                    backgroundColor: props.theme.palette.background.default
                                }
                            })
                        },
                        headCell: {
                            elementAttributes: () => ({
                                className: 'MuiTableHead-root',
                                style: {
                                    backgroundColor: props.theme.palette.mode == 'light' ? props.theme.palette.grey['50'] : props.theme.palette.grey['A700'],
                                    color: props.theme.palette.text.primary
                                }
                            }),
                        },
                        cellText: {
                            elementAttributes: () => ({
                                className: 'MuiTableHead-root',
                                style: {
                                    backgroundColor: props.theme.palette.background.default,
                                    color: props.theme.palette.text.primary
                                }
                            }),
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'editColumn': return <SaveButton {...props} />;
                                    case 'img': return <Box component="img" src={_.isEmpty(props.rowData.img) ? null : props.rowData.img} sx={{
                                        height: 100,
                                        width: 100
                                    }} />;
                                }
                            }
                        }
                    }}
                    dispatch={dispatch}
                />
            </TableContainer>
        </Container>
    );
};

export default CommunityChapterTable;
