import React, { useEffect } from 'react';
import HyperFeederBanner from '../components/Banner/hyperfeederBanner';
import Countdown from './Countdown'; // import the Countdown component
import ImageComponent from './ImageComponent'; // import the ImageComponent
import myImage from '../static/images/hyperfeeder/hypercatcher.eth_a_group_of_robots_standing_in_a_circle_around__bfa4f612-3acd-432a-ae95-c713fff2a7b0.png'; // Import your local image


function HyperFeeder() {

    useEffect(() => { 
        document.body.style.backgroundImage = 'linear-gradient(#e66465, #9198e5)';
    } , [])

    return (
        <div>
            <Countdown />
            <HyperFeederBanner />
            <ImageComponent imgUrl={myImage} />
        </div>
    );
}

export default HyperFeeder;
