
import { Component } from 'react';
import Podcast from './models/podcast';
import { ListItemButton, Pagination, Container, Typography, Box, Paper, Chip, Skeleton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'
import _ from 'lodash';

interface IProps {
    podcasts: Array<Podcast>,
    handleItemClicked: any,
}

interface IState {
    selectedIndex: string | number | undefined,
    podcasts: Array<Podcast> | null,
}

class PodcastList extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedIndex: 0,
            podcasts: null,
        };
    }

    render() {
        if (this.props.podcasts) {
            return (
                <Container >
                    {this.buildPodcastList(this.state.selectedIndex as number)}
                        { this.props?.podcasts?.length > 9 ? <Pagination count={this.totalPages() } onChange={this.onPaginationSelect }  /> : null }
                </Container >
            );
        }
        return (
            <Container>
            <Grid2 container spacing={2} >
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        <Grid2 xs={20}> 
            <Skeleton variant="rectangular" height={200} />
        </Grid2>
        </Grid2>
        </Container>
        );;
    }

    totalPages() {
        if (this.props.podcasts && this.props.podcasts.length) {
            return Math.floor(this.props.podcasts.length / 10) + 1;
        }
        return 0;
    }

    onPaginationSelect = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ selectedIndex: value - 1 });
    }

    buildPodcastList(selectedIndex: number) {
        return ( 
        <Grid2 container spacing={2} >

            {
            this.props.podcasts?.map((item: any, index: number) => {
                
                if ((index >= (selectedIndex * 10)) && (index < (selectedIndex + 2) * 10)) {
                    return (
                    <Grid2 xs={9}>
                    <Paper elevation={3} > 
                    <ListItemButton onClick={(e) => {this.props.handleItemClicked(e, item);}}>
                    <Box component="img" src={_.isEmpty(item.podcastImageUrl) ? null : item.podcastImageUrl} sx={{
                                    height: 100,
                                    width: 100,
                                    mr: 4
                                }}>
                    </Box>
                    <Typography variant="body1"> {item.title} </Typography>
                    </ListItemButton>
                    </Paper>
                    </Grid2>
                    );
                }
            })
            }
            </Grid2>
        )
    }
}

export default PodcastList;