import { Component } from "react";
import { AppBar, Container, Box, Typography, Breadcrumbs, Link, Button, Stack, TableContainer, Paper, Toolbar } from '@mui/material';
import Podcast from "./models/podcast";
import SmartChapter from "./models/smartChapter";
import SmartChapterTable from "./routes/smartChapterTable";
import { withRouter } from "./routes/withRouter";
import { getLoggedInUser } from "./utils/firebase";
import HCAppBar from "./HCAppBar";
import { makeAuthenticatedRequest } from './utils/api';
const _ = require('lodash');

interface IProps {
    params_url: string | null,
    podcastId: any,
    location: any,
}

interface IState {
    podcastId: string,
    studioPodcastDetail: Podcast | null,
    smartChapters: Array<SmartChapter>
}

class SmartChaptersController extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            podcastId: this.props.podcastId,
            studioPodcastDetail: null,
            smartChapters: []
        }
        this.getStudioPodcast();
    }

    handleUpdateChapters = async () => {
        try {
            await makeAuthenticatedRequest('/api/podcast/smartChapters/update', {
                method: 'POST',
                body: {
                    'smartChapters': this.state.smartChapters,
                    'podcastId': this.state.podcastId
                }
            });
        } catch (error) {
            console.error('Error updating chapters:', error);
        }
    }

    getStudioPodcast = async () => {
        try {
            const response = await makeAuthenticatedRequest('/api/studio/podcast', {
                method: 'POST',
                body: {
                    'podcastId': this.props.podcastId
                }
            });

            if (response != null) {
                let podcastDetail = Podcast.initFromJson(response);
                this.setState({
                    studioPodcastDetail: podcastDetail
                });
            }
        } catch (error) {
            console.error('Error fetching studio podcast:', error);
        }
    }

    getLatestSmartChapters = (chapterResult: any) => {
        console.log('get latest chapters');
        console.log(chapterResult);
        if (!_.isEqual(chapterResult, this.state.smartChapters)) {
            this.setState({ smartChapters: chapterResult });
        }
    }

    render() {
        return <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <HCAppBar toggleColorMode={this.context.toggleColorMode} />

            <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>

                <Breadcrumbs >
                    <Link href="/dashboard"><Typography> Home </Typography></Link>
                    <Link href={`/dashboard/${this.props.podcastId}`}><Typography>{`${this.props.podcastId}`}</Typography></Link>
                    <Typography>Smart Chapters</Typography>
                </Breadcrumbs>

                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <p className="title">Publish Options</p>
                        <Stack spacing={2} direction="row">
                            <Button variant="outlined" onClick={() => { this.handleUpdateChapters() }}>Save Changes</Button>
                        </Stack>
                    </Paper>
                </Container >

                <section className="topic">
                    <h2>Podcast Chapters</h2>
                </section>

                <div className='nes-table-responsive'>
                    {this.state.podcastId
                        ? <SmartChapterTable
                            studioPodcastDetail={this.state.studioPodcastDetail}
                            getLatestSmartChapters={this.getLatestSmartChapters}
                        /> : null}
                </div>
            </Container>
        </Box >
    }
}

export default withRouter(SmartChaptersController);
