import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useParams, Link } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseManager } from './utils/firebase'
import SignInScreen from './SignInScreen';
import { RequireAuth, AuthProvider } from './RequireAuth';
import Dashboard from './routes/dashboard';
import DashboardEpisodes from './routes/dashboard-episodes';
import ChapterEditor from './routes/chapter-editor';
import SmartChaptersController from './SmartChaptersController';
import { useTheme } from '@mui/system';
import HomePage from './routes/homepage';
import SuggestedChaptersTriggersController from './SuggestedChaptersTriggersController';
import SignUpScreen from './SignUpScreen';
import AddNewPodcast from './routes/dashboard-addNewPodcast';
import HyperFeeder from './routes/hyperfeeder';

// const styleLink = document.createElement("link");
// styleLink.rel = "stylesheet";
// styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
// document.head.appendChild(styleLink);

ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<App />} >
            <Route path="/" element={<HomePage />} />
            <Route path="/hyperfeeder" element={<HyperFeeder />} />
            <Route path="/signin/" element=
              {
                <SignInScreen firebase={FirebaseManager} />
              } />
            <Route path="/signup/" element=
              {
                <SignUpScreen firebase={FirebaseManager} />
              } />
            <Route path="/dashboard" element={
              <RequireAuth >
                <Dashboard />
              </RequireAuth>
            } />
            <Route path="/addPodcast" element={
              <RequireAuth >
                <AddNewPodcast />
              </RequireAuth>
            } />
            <Route path="/dashboard/:podcastId" element={
              <RequireAuth >
                <RenderDashboardEpisodes />
              </RequireAuth>
            } />
            <Route path="/dashboard/:podcastId/smartChapters" element={
              <RequireAuth >
                <RenderSmartChaptersController />
              </RequireAuth>
            } />
            <Route path="/dashboard/:podcastId/suggestedChaptersTriggers" element={
              <RequireAuth >
                <RenderSuggestedChaptersController />
              </RequireAuth>
            } />
            <Route path="/dashboard/:podcastId/episode/:episodeId" element={
              <RequireAuth >
                <RenderChapterEditor />
              </RequireAuth>
            } />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// function RenderPlayer() {
//   let params = useParams();
//   return <Player params_url={params.url ?? null} />
// }



function RenderDashboardEpisodes() {
  let params = useParams();
  const theme = useTheme();
  return <DashboardEpisodes podcastId={params.podcastId} theme={theme} />
}

function RenderChapterEditor() {
  let params = useParams();
  const theme = useTheme();
  return <ChapterEditor podcastId={params.podcastId} episodeId={params.episodeId} theme={theme} />
}

function RenderSmartChaptersController() {
  let params = useParams();
  const theme = useTheme();
  return <SmartChaptersController podcastId={params.podcastId} episodeId={params.episodeId} theme={theme} />
}

function RenderSuggestedChaptersController() {
  let params = useParams();
  const theme = useTheme();
  return <SuggestedChaptersTriggersController podcastId={params.podcastId} episodeId={params.episodeId} theme={theme} />
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
