
export type PodcastValueBlockFormState = {
  type: string;
  method: string;
  suggested: string;
};

export type ValueBlockRecipientFormState = {
  fields: {
    name: string;
    type: string;
    address: string;
    split: string;
    customValue: string;
    customKey: string;
  }[];
};

export class FullValueBlockFormState {
  valueBlock: PodcastValueBlockFormState;
  valueBlockRecipients: ValueBlockRecipientFormState;

  constructor(valueBlock: PodcastValueBlockFormState, valueBlockRecipients: ValueBlockRecipientFormState) {
    this.valueBlock = valueBlock;
    this.valueBlockRecipients = valueBlockRecipients;
  }

}