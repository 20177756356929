import Episode from "./episode";
import SmartChapter from "./smartChapter";
import Trigger from "./trigger";

class Podcast {

    title: string;
    rssUrl: string;
    smartChapters: Array<SmartChapter>;
    triggers: Array<Trigger>;
    id: string;
    transcriptTokens: number;
    podcastImageUrl: string | undefined;
    episodes: Array<Episode> | undefined;

    constructor(title: string, rssUrl: string, id: string, smartChapters: Array<SmartChapter>, triggers: Array<Trigger>, transcriptTokens: number, podcastImageUrl?: string, episodes?: Array<Episode>) {
        this.title = title;
        this.rssUrl = rssUrl;
        this.id = id;
        this.smartChapters = smartChapters;
        this.triggers = triggers;
        this.transcriptTokens = transcriptTokens;
        this.podcastImageUrl = podcastImageUrl;
        this.episodes = episodes;
    }

    static initFromJson(response: any) {
        let smartChapters = [];
        let triggers = [];
        let transcriptTokens = 0;
        if (response.hasOwnProperty('transcriptTokens')) {
            transcriptTokens = response.transcriptTokens;
        }
        if (response.hasOwnProperty('smart_chapters')) {
            try {
                const smartChaptersArr = JSON.parse(response.smart_chapters);
                smartChapters = smartChaptersArr.map((smartChapter: any) => {
                    return new SmartChapter(smartChapter.id, smartChapter.image, smartChapter.name, smartChapter.title, smartChapter.url);
                });
            } catch {
                console.log('no smart chapters found or could not be parsed');
            }

            try {
                const triggersArr = JSON.parse(response.triggers);
                triggers = triggersArr.map((trigger: any) => {
                    return new Trigger(trigger.triggerWord, trigger.smartChaptersName, trigger.id);
                });
            } catch {
                console.log('no smart chapters found or could not be parsed');
            }            
        }
        return new Podcast(response.title, response.rssUrl, response.id, smartChapters, triggers, transcriptTokens, response.podcastImageUrl, response.episodes);
    }
}

export default Podcast;