import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import Brightness4Icon from '@mui/icons-material/Brightness4';

function HCAppBar(toggleColorMode: any) {

  return (
    <AppBar position="absolute" color="primary">
    <Toolbar>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}>
        HyperCatcher Studio
      </Typography>
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="default">
        {/* {this.context.theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />} */}
        <Brightness4Icon />
      </IconButton>
    </Toolbar>
  </AppBar>
  );
}

export default HCAppBar;