// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDD5UKRnaPTw6GugNdKy1nwLbtvYUnFGWY",
  authDomain: "precise-equator-290115.firebaseapp.com",
  databaseURL: "https://precise-equator-290115.firebaseio.com",
  projectId: "precise-equator-290115",
  storageBucket: "precise-equator-290115.appspot.com",
  messagingSenderId: "472112609417",
  appId: "1:472112609417:web:34a241cf0be47220122e58",
  measurementId: "G-19Z6QX3N2E"
};

// Initialize Firebase
export let FirebaseManager = firebase.initializeApp(firebaseConfig);

const onAuthStateChangedPromise = new Promise((resolve, reject) => {
  FirebaseManager.auth().onAuthStateChanged(user => {
    resolve(user)
  }, err => {
    reject(err)
  })
})

export const getLoggedInUser = () => onAuthStateChangedPromise