import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useText } from '../../theme/common';
import useStyles from './banner-style';
import hcHand from "../../static/HC-website-polygon.svg";


function HyperFeederBanner() {

  const classes = useStyles();
  const text = useText();
  const elem = useRef(null);

  const theme = useTheme();

  const [hide, setHide] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.root} ref={elem}>
      <div className={classes.canvasWrap}>
        <div className={classes.overlay}>
          <div className={clsx(classes.decoInner, hide && classes.hide)}>
          </div>
        </div>
      </div>
      <Container fixed>
        <div className={classes.bannerWrap}>
          <Grid container alignItems="center" spacing={6}>
            <Grid item xs={12}>
              <div className={classes.text}>
                <Typography variant="h4" className={text.title2}>
                  {"Podcast Generator Input Cycle In Progress!"}
                </Typography>
                <Typography component="p" className={text.subtitle2}>
                  {'Please submit your input before the next cycle begins to augment the next episode.'}
                </Typography>
              </div>
              <div className={classes.btnArea}>
              <a href='https://github.com/normand1/ai-ai-podcast-config' target="_blank" rel="noopener noreferrer">
                <Button variant="contained" color="secondary" size="large" fullWidth={isMobile}>
                {'Submit Input Now'}
                </Button>
              </a>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <figure className={classes.objectArt}>
                <img src={hcHand} alt="hand" />
              </figure>
            </Grid>
          </Grid>
        </div>
      </Container>
      {/* <div className={classes.decoBottom}>
        <svg>
          <use xlinkHref="/images/crypto/deco-banner.svg#main" />
        </svg>
      </div> */}
    </div>
  );
}

export default HyperFeederBanner;
