class Trigger {

    triggerWord: string
    smartChaptersName: string
    id: string

    constructor(triggerWord: string, smartChaptersName: string, id: string) {
        this.triggerWord = triggerWord;
        this.smartChaptersName = smartChaptersName;
        this.id = id;
    }

    static initFromJson(response: any) {
        return new Trigger(response.triggerWord, response.smartChaptersName, response.id);
    }
}

export default Trigger;