class Episode {

    title: string
    url: string
    guid: string
    pubDateTime: number
    imageUrl?: string
    chapters?: string
    community_chapters?: Array<string>
    transcriptUrl?: string

    constructor(title: string, url: string, guid: string, pubDateTime: number, imageUrl?: string, chapters?: string, community_chapters?: Array<string>, transcriptUrl?: string) {
        this.title = title;
        this.url = url;
        this.guid = guid;
        this.pubDateTime = pubDateTime;
        this.imageUrl = imageUrl;
        this.chapters = chapters ? JSON.parse(chapters) : null;
        this.community_chapters = community_chapters;
        this.transcriptUrl = transcriptUrl;
    }
}

export default Episode;