import Chapter from "./chapter"

class EpisodeDetail {

    chapters: Array<Chapter> | null
    community_chapters: string | null
    podcast_transcript_url: string | null
    pubDateTime: any | null
    title: string | null

    static initFromJson(json: any) {
        let chapters = null;
        let community_chapters = null;
        let podcast_transcript_url = null;
        let pubDateTime = null;
        let title = null;

        if (json.chapters) {
            let jsonChapters = JSON.parse(json.chapters);
            chapters = jsonChapters.map(
                (chapter:any) => {
                    return new Chapter(chapter.title, chapter.startTimeInSec, chapter.endTimeInSec, chapter.url, chapter.img, chapter.elementId, chapter.formattedStartTimeInSec, chapter.smartChapters, chapter.toc)
                    }
            )
        }
        if (json.community_chapters) {
            community_chapters = json.community_chapters.map((jsonChapters:any) => { 
                let chapter = JSON.parse(jsonChapters);
                return new Chapter(chapter.title, chapter.startTimeInSec, chapter.endTimeInSec, chapter.url, chapter.img, chapter.elementId, chapter.formattedStartTimeInSec, chapter.smartChapters, chapter.toc)})
        }
        if (json.podcast_transcript_url) {
            podcast_transcript_url = json.podcast_transcript_url
        }
        if (json.pubDateTime) {
            pubDateTime = json.pubDateTime
        }
        if (json.title) {
            title = json.title
        }

        let episodeDetail = new EpisodeDetail(chapters, community_chapters, podcast_transcript_url, pubDateTime, title);
        return episodeDetail;
            
    }

    constructor(chapters: Array<Chapter> | null, community_chapters: string | null, podcast_transcript_url: string | null, pubDateTime: string | null, title: string | null) {
        this.chapters = chapters;
        this.community_chapters = community_chapters;
        this.podcast_transcript_url = podcast_transcript_url;
        this.pubDateTime = pubDateTime;
        this.title = title;
    }
}

export default EpisodeDetail;