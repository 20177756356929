import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom'
import { getLoggedInUser } from './utils/firebase';

interface AuthContextType {
    user: any;
    signin: (user: any) => void;
    signout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {

    let [user, setUser] = useState<any>(null);

    let signin = (userVal: any) => {
        setUser(userVal);
        window.sessionStorage.setItem("user", userVal);
        console.log(user);
    };

    let signout = (callback: VoidFunction) => {
        console.log('ran sign out');
    };

    let value = { user, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    let auth = useAuth();
    // var user = await getLoggedInUser();
    let userSession = window.sessionStorage.getItem("user");
    if (!userSession) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    return children;
}