
class ResultModel {
  
  message: string;
  statusCode: number;
  isError: boolean;

  constructor(message: string, statusCode: number, isError: boolean) {
    this.message = message;
    this.statusCode = statusCode;
    this.isError = isError;
  }
}

export default ResultModel;