import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '../components/Typography';
import chatBubbleImg from "../../../static/bubble.svg";
import usersImg from "../../../static/users.svg";
import podcastImg from "../../../static/podcast.svg";
import useStyles from './product-values-style';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  const classes = useStyles();

  return (
    // <div className={classes.root} ref={elem}>
    //   <div className={classes.canvasWrap}>
    //     <div className={classes.overlay}>
    //       <div className={clsx(classes.decoInner, hide && classes.hide)}>
    //       </div>
    //     </div>
    //   </div>
        <Grid container spacing={5} justifyContent='center'   alignItems="center"
         className={classes.canvasWrap}>
          <Grid container item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={chatBubbleImg}
                alt="chat bubble"
                sx={{ height: 55 }}
              />
              <br/>
              <Typography color="common.white" variant="h6" sx={{ my: 5 }}>
                Podcast Chapters Editor
              </Typography>
              <br/>
              <Typography color="common.white" variant="h5">
                {
                  'Generate Podcast Chapters meeting the Podcasting 2.0 Spec'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={usersImg}
                alt="users"
                sx={{ height: 55 }}
              />
              <br/>
              <Typography color="common.white" variant="h6" sx={{ my: 5 }}>
                New experiences
              </Typography>
              <br/>
              <Typography color="common.white" variant="h5">
                {
                  'Create new and unique experiences for your listeners'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={podcastImg}
                alt="podcasting 2.0"
                sx={{ height: 55 }}
              />
              <br/>
              <Typography color="common.white" variant="h6" sx={{ my: 5 }}>
                Podcasting 2.0
              </Typography>
              <br/>
              <Typography color="common.white" variant="h5">
                {'Join the Podcasting 2.0 ecosystem'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      // </div>
  );
}

export default ProductValues;
