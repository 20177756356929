class SmartChapter {

    id: number
    image: string
    name: string
    title: string
    url: string

    constructor(id: number, image: string, name: string, title: string, url: string) {
        this.id = id;
        this.image = image;
        this.name = name;
        this.title = title;
        this.url = url;
    }

    static initFromJson(response: any) {
        return new SmartChapter(response.id, response.image, response.name, response.title, response.url);
    }
}

export default SmartChapter;