import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import './Countdown.css';

type CountdownTime = {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
}

function Countdown() {
    const calculateTimeLeft = (): CountdownTime => {
        const now = moment();
        const target = now.clone().tz('America/Los_Angeles').startOf('day').add(6, 'hours');

        if (now.isAfter(target)) {
            target.add(1, 'day');
        }

        const difference = target.diff(now);
        let timeLeft: CountdownTime = {
          days: Math.floor(moment.duration(difference).asDays()),
          hours: moment.duration(difference).hours(),
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        };

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<CountdownTime>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="container">
            <div className="item">{timeLeft.days}d</div>
            <div className="item">{timeLeft.hours}h</div>
            <div className="item">{timeLeft.minutes}m</div>
            <div className="item">{timeLeft.seconds}s</div>
        </div>
    );
}

export default Countdown;
