import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Container, Paper, TableContainer } from '@mui/material';
import { ITableProps, kaReducer, Table } from "ka-table";
import { closeEditor, deleteRow, hideNewRow, insertRow, loadData, saveNewRow, updateCellValue, updateData } from "ka-table/actionCreators";
import {
    DataType,
    EditingMode,
    SortDirection,
    SortingMode
} from "ka-table/enums";
import { ICellEditorProps, ICellTextProps, IHeadCellProps } from 'ka-table/props';
import { DispatchFunc } from "ka-table/types";
import { kaPropsUtils } from 'ka-table/utils';
import React, { useState } from "react";
import { uuidv4, } from "../chapterHelper";
import SmartChapter from "../models/smartChapter";
import { makeAuthenticatedRequest } from '../utils/api';
const _ = require('lodash');

interface ITimeAwareHeadCellProps extends IHeadCellProps {
    totalTimePlayed: number,
}

interface IHCCellEditorProps extends ICellEditorProps {
    smartChapters: Array<SmartChapter>
}

const DeleteRow: React.FC<ICellTextProps> = ({ dispatch, rowKeyValue }) => {
    return (
        <Button
            onClick={() => dispatch(deleteRow(rowKeyValue))}
        >x</Button>
    );
};

const AddButton: React.FC<ITimeAwareHeadCellProps> = ({
    dispatch, totalTimePlayed
}) => {
    return (
        <div className='plus-cell-button'>
            <Button variant="outlined"
                onClick={() => {
                    let keyVal = uuidv4();
                    const newRow = {
                        id: keyVal,
                        title: '',
                    };
                    dispatch(insertRow(newRow, { rowKeyValue: keyVal }))
                }}>
                <AddCircleOutlineIcon></AddCircleOutlineIcon>
            </Button>
        </div>
    );
};

const SaveButton: React.FC<ICellEditorProps> = ({
    dispatch
}) => {
    const saveNewData = () => {
        const rowKeyValue = uuidv4();
        dispatch(saveNewRow(rowKeyValue, {
            validate: true
        }));
    };
    return (
        <div className='buttons'>
            <Button
                onClick={saveNewData}
            >s</Button>
            <Button
                onClick={() => dispatch(hideNewRow())}
            >c</Button>
        </div>
    );
};

const CustomLookupEditor: React.FC<IHCCellEditorProps> = ({
    column, dispatch, rowKeyValue, value, smartChapters
}) => {

    const close = () => {
        dispatch(closeEditor(rowKeyValue, column.key));
    };
    let options = Array<JSX.Element>();
    options.push(<option value={'none'}>{'none'}</option>)
    if (smartChapters) {
        for (let i = 0; i < smartChapters.length; i++) {
            options.push(<option value={smartChapters[i].name}>{smartChapters[i].name}</option>)
        }
    }
    const [editorValue, setValue] = useState(value);

    return (
        <div>
            <select
                className='form-control'
                autoFocus={true}
                defaultValue={editorValue}
                onBlur={(event) => {
                    close();
                }}
                onChange={(event) => {
                    if (event.target.selectedIndex === 0) {
                        dispatch(updateCellValue(rowKeyValue, column.key, ''));
                    } else {
                        dispatch(updateCellValue(rowKeyValue, column.key, smartChapters[event.target.selectedIndex - 1].name));
                    }
                }}
                >
                {options ? options : null}
            </select>
        </div >
    );
};

const tablePropsInit: ITableProps = {
    columns: [
        { dataType: DataType.String, key: "id", title: "id", width: 100, visible: false, sortDirection: SortDirection.Ascend },
        { dataType: DataType.String, key: "smartChaptersName", title: "Smart Chapter", width: 100, visible: true},
        { dataType: DataType.String, key: "triggerWord", title: "Trigger Words", width: 100, },
        { title: "Delete", key: ':delete', width: 50, style: { textAlign: 'center' }, isEditable: false },
        {
            key: 'addColumn',
            style: { width: 50 }
        },
    ],
    validation: ({ column, value }) => {
        if (column.key === 'triggerWord') {
            if (!value) {
                return `Trigger words can't be empty`;
            }
            const wordCount =   value.split(/\S+/).length - 1;
            if (wordCount < 2) {
                return `You must enter more than one word to trigger on`;
            }
        }
    },
    singleAction: loadData(),
    editingMode: EditingMode.Cell,
    sortingMode: SortingMode.Single,
    rowKeyField: "id",
    columnResizing: true
};

const SuggestedChaptersTriggersTable: React.FC<any> = (props: any) => {

    let smartChapters: Array<SmartChapter>;
    const totalTimePlayed: number = Math.ceil(props.totalTimePlayed);
    const [tableProps, changeTableProps] = useState(tablePropsInit);
    const [triggers, setTriggers] = useState([]);
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevProps: ITableProps) => kaReducer(prevProps, action));
    };

    if (props.studioPodcastDetail && props.studioPodcastDetail.smartChapters) {
        smartChapters = props.studioPodcastDetail.smartChapters;
    }

    if (props.studioPodcastDetail && props.studioPodcastDetail.triggers && !_.isEqual(props.studioPodcastDetail.triggers, triggers)) {
        setTriggers(props.studioPodcastDetail.triggers)
        dispatch(updateData(props.studioPodcastDetail.triggers));
    }

    props.getLatestTriggers(kaPropsUtils.getData(tableProps));

    return (
        <Container maxWidth="xl">
            <TableContainer component={Paper}>
                <Table
                    {...tableProps}
                    childComponents={{
                        cellEditor: {
                            content: (props) => {
                                switch (props.column.key) {
                                    case 'addColumn': return <SaveButton {...props} />;
                                    case 'smartChaptersName': return <CustomLookupEditor {...props} smartChapters={smartChapters} />;
                                }
                            }
                        },
                        cellText: {
                            content: (props) => {
                                switch (props.column.key) {
                                    case ':delete': return <DeleteRow {...props} />;
                                    case 'smartChaptersName': return _.isEmpty(props.rowData.smartChaptersName) ? (<label>Select Smart Chapter</label>) : null;
                                }
                            }
                        },
                        headCell: {
                            content: (props) => {
                                if (props.column.key === 'addColumn') {
                                    return <AddButton {...props} totalTimePlayed={totalTimePlayed} />;
                                }
                            }
                        }
                    }}
                    dispatch={dispatch}
                />
            </TableContainer>
        </Container>
    );
};

export default SuggestedChaptersTriggersTable;
