import { getLoggedInUser } from './firebase';  // Adjust import path as needed

interface RequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  additionalHeaders?: Record<string, string>;
}

export async function makeAuthenticatedRequest(
  endpoint: string, 
  options: RequestOptions = {}
) {
  const user = await getLoggedInUser();
  if (!user) {
    throw new Error('User not authenticated');
  }

  const authToken = await user.getIdToken(true);
  
  const requestOptions = {
    method: options.method || 'GET',
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      ...options.additionalHeaders
    },
    ...(options.body && { body: JSON.stringify(options.body) })
  };

  const response = await fetch(`https://hypercatcher-backend-msb3tiapcq-uc.a.run.app${endpoint}`, requestOptions);
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  const responseText = await response.text();
  if (!responseText) {
    return null;
  }
  
  try {
    return JSON.parse(responseText);
  } catch {
    return responseText;
  }
}
